import React, { useState, useEffect } from 'react';
import { useAccount } from '../hooks/account';
import { Form, Button } from 'react-bootstrap';
import { CircleStencil, FixedCropper, ImageRestriction } from 'react-advanced-cropper';
import 'react-advanced-cropper/dist/style.css';
import 'react-advanced-cropper/dist/themes/compact.css';

const ProfilePicture = ({ newPicture, editablePic, setEditablePic, setNewPicture, handleEdit, fileInputRef, isEditable = false }) => {
    const { profile } = useAccount();
    const [profilePicture, setProfilePicture] = useState("https://picsum.photos/200/200");
    const [showCrop, setShowCrop] = useState(false);
    const [cropData, setCropData] = useState(null);

    useEffect(() => {
        setProfilePicture(newPicture || profile.profile_picture || "https://picsum.photos/200/200");
    }, [profile.profile_picture, newPicture]);

    useEffect(() => {
        return () => {
            if (newPicture) URL.revokeObjectURL(newPicture);
        };
    }, [newPicture]);

    const handleImageClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleRevertPicture = () => {
        setShowCrop(false);
        setNewPicture(null);
        setEditablePic(false);
        if (fileInputRef.current) {
            fileInputRef.current.value = null;
        }
    };

    const handleSavePicture = () => {
        setShowCrop(false);
        setNewPicture(null);
        if (cropData) {
            const cropperInstance = cropData.getCanvas();
            if (cropperInstance) {
                const croppedImage = cropperInstance.toDataURL();
                setNewPicture(croppedImage);
                setEditablePic(false);
                return
            }
        }
    };

    const handleFileChange = (e) => {
        handleEdit(true);
        setNewPicture(null);
        const file = e.target.files[0];
        if (file) {
            setShowCrop(true);
            const url = URL.createObjectURL(file);
            setNewPicture(url);
            setEditablePic(true);
        }
    };

    const defaultSize = ({ imageSize, visibleArea }) => ({
        width: (visibleArea || imageSize).width,
        height: (visibleArea || imageSize).height,
    });

    return (
        <>
            {!showCrop && (
                <div className={`${isEditable ? '' : 'bitcoin-icon-container'}`}>
                    <img
                        onClick={isEditable ? handleImageClick : undefined}
                        style={{ cursor: isEditable ? 'pointer' : 'default', width: '200px', height: '200px', objectFit: 'cover' }}
                        src={profilePicture}
                        className="img-fluid mb-3 border border-primary border-3 rounded-circle"
                        alt="Profile"
                    />
                </div>
            )}
            {isEditable && (
                <>
                    <Form.Control
                        type="file"
                        ref={fileInputRef}
                        accept=".jpg, .jpeg, .png"
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                    />
                    {newPicture && editablePic && showCrop && (
                        <div className="rounded-circle mb-3 border border-primary border-3 overflow-hidden" style={{ height: '200px', width: '200px'}}>
                            <FixedCropper
                                src={newPicture}
                                stencilSize={{ width: 200, height: 200 }}
                                defaultSize={defaultSize}
                                stencilProps={{
                                    handlers: false,
                                    lines: false,
                                    movable: false,
                                    resizable: false,
                                }}
                                style={{ height: '100%', width: '100%',cursor:'grabbing' }}
                                aspectRatio={1}
                                stencilComponent={CircleStencil}
                                imageRestriction={ImageRestriction.stencil}
                                onChange={(cropper) => setCropData(cropper)}
                            />
                        </div>
                    )}
                    {newPicture && editablePic && (
                        <div className="mt-3 mb-3">
                            <Button
                                variant="danger"
                                onClick={handleRevertPicture}
                                className="me-2 btn-sm"
                            >
                                Revert
                            </Button>
                            <Button
                                variant="success"
                                onClick={handleSavePicture}
                                className="btn-sm"
                            >
                                Accept
                            </Button>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default ProfilePicture;
