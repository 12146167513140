import React, { useState, useEffect } from 'react';
import { Navbar, Nav, ButtonGroup, Button, Dropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faMoon, faSun, faBitcoinSign, faSignInAlt, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '../hooks/theme-context';
import { useAccount } from '../hooks/account';
import { useNavigate } from 'react-router-dom';

const NavbarComponent = () => {
  const { darkMode, toggleTheme } = useTheme();
  const { actions: userActions, profile, tokens, appMenu = [] } = useAccount();
  const { logout } = userActions
  const iconColor = darkMode ? 'text-dark' : 'text-light';
  const btnColor = darkMode ? 'light' : 'dark';
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(profile.referal_code)
      .then(() => {
        console.log('Text copied to clipboard!');
      })
      .catch((error) => {
        console.error('Failed to copy text: ', error);
      });
  };

  const handleNavigate = (link) => {
    setIsCollapsed(true)
    navigate(link)
  }
  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const actionButtons = (
    <Nav className="d-flex align-items-center justify-content-end">
      <ButtonGroup className={`${isMobile ? 'mt-2 mb-2 w-60' : 'ms-2 me-4'}`} >
        {tokens.access_token ? (
          <>
            <Button variant={btnColor} size={`${isMobile ? 'sm' : ''}`} className={`text-${darkMode}`} >
              <b>{`${profile.user_balance}/${profile.user_currency}`}</b>
            </Button>
            <Button variant={btnColor} size={`${isMobile ? 'sm' : ''}`} className={`text-${darkMode}`} onClick={handleCopyClick} >
              <b>{`${profile.referal_code}`}</b>
            </Button>
            <Button variant={btnColor} size={`${isMobile ? 'sm' : ''}`} onClick={toggleTheme}>
              <FontAwesomeIcon icon={darkMode ? faSun : faMoon} size="lg" className={iconColor} />
            </Button>
            <Button variant={btnColor} size={`${isMobile ? 'sm' : ''}`} onClick={logout}>
              <FontAwesomeIcon icon={faPowerOff} size="lg" className={iconColor} />
            </Button>
          </>
        ) : (
          <>
            <Button variant={btnColor} onClick={toggleTheme}>
              <FontAwesomeIcon icon={darkMode ? faSun : faMoon} size="lg" className={iconColor} />
            </Button>
            <LinkContainer to="/login">
              <Button variant={btnColor}>
                <FontAwesomeIcon icon={faSignInAlt} size="lg" className={iconColor} />
              </Button>
            </LinkContainer>
            <LinkContainer to="/register">
              <Button variant={btnColor}>
                <FontAwesomeIcon icon={faUserPlus} size="lg" className={iconColor} />
              </Button>
            </LinkContainer>
          </>
        )}
      </ButtonGroup>
    </Nav>
  )

  return (
    <Navbar
      collapseOnSelect
      fixed="top"
      bg={darkMode ? 'dark' : 'secondary'}
      variant={darkMode ? 'dark' : 'light'}
      expand="md"
      className={`navbar-${darkMode ? 'dark' : 'light'} top-0 start-0 w-100 `}
    >
      {isMobile ? <><span className={`bg-${darkMode ? 'light' : 'dark'} ${isMobile ? 'ms-1 ps-1 pe-2' : 'ps-1 pe-3'} rounded navbar-brand-extra`} >
        <LinkContainer to="/">
          <FontAwesomeIcon className={` ${iconColor} ${isMobile ? 'ms-1' : 'ms-3'} `} size={`${isMobile ? 'sm' : 'xl'}`} icon={faBitcoinSign} />
        </LinkContainer>
      </span><div className='ms-2'>{actionButtons}</div></> : <Navbar.Brand className={`bg-${darkMode ? 'light' : 'dark'} ps-1 pe-3 rounded`} >
        <LinkContainer to="/">
          <FontAwesomeIcon className={`ms-3 ${iconColor}`} size="xl" icon={faBitcoinSign} />
        </LinkContainer>
      </Navbar.Brand>}
      <Navbar.Toggle
        onClick={handleToggle} aria-controls="basic-navbar-nav"
        className={` ${isCollapsed ? 'collapsed' : ''}`} />
      <Navbar.Collapse id="basic-navbar-nav" className={`${isCollapsed ? 'collapse' : ''} `}>
        <Nav className={`${isMobile ? 'ms-4 me-4' : 'me-auto'}`}>
          <Button variant={btnColor} onClick={() => { navigate('/') }} className={`text-${darkMode} w-100 me-3 ${isMobile ? 'mt-2' : ''}`}>Home</Button>
          {tokens.access_token && appMenu && appMenu.map(app => (
            <Dropdown data-bs-theme={btnColor} key={`${app.id}-dropdown`} className='w-100 me-3'>
              <Dropdown.Toggle id={`${app.id}-${app.app_name}`} variant={btnColor} className={`btn  text-${darkMode} w-100 me-3 ${isMobile ? 'mt-2' : ''}`} >
                {app.app_name}
              </Dropdown.Toggle>
              <Dropdown.Menu className={`p-0 col-10 mx-auto`}>
                {app.models.map(model => (
                  <Dropdown.Item eventKey={`${app.app_name}-model-${model.id}`} key={`${app.app_name}-model-${model.id}`} onClick={() => { handleNavigate(model.link) }} className={`btn btn-sm mt-1 mb-1 btn-${btnColor} text-center`} >
                    {model.model_name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          ))}
        </Nav>
        {!isMobile && actionButtons}
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavbarComponent;
