import { Cookies } from 'react-cookie';

const cookies = new Cookies();

class WebSocketClient {
  constructor(url) {
    this.url = url;
    this.socket = null;
    this.path = null;
    this.listeners = [];
    this.reconnectAttempts = 0;
    this.maxReconnectAttempts = 5;
    this.token = cookies.get('access_token');
    this.checkTokenInterval = setInterval(this.checkToken.bind(this), 1000);
  }

  connect(path) {
    this.path = path;
    if (this.socket) {
      console.warn('WebSocket is already connected.');
      return;
    }
    this.token = cookies.get('access_token');

    const fullUrl = `${this.url}${this.path}?token=${this.token}`;
    if (!this.token) {
      return;
    }
    this.socket = new WebSocket(fullUrl);

    this.socket.onopen = () => {
      console.log('WebSocket connection established');
      this.reconnectAttempts = 0;
    };

    this.socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      this.notifyListeners(data);
    };

    this.socket.onerror = (error) => {
      console.error('WebSocket error:', error);
      this.handleReconnect();
    };

    this.socket.onclose = (event) => {
      console.log('WebSocket connection closed', event);
      this.handleReconnect();
    };
  }

  sendMessage(message) {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify(message));
    } else {
      console.error('WebSocket is not open. Message not sent:', message);
    }
  }

  addListener(callback) {
    this.listeners.push(callback);
  }

  notifyListeners(data) {
    this.listeners.forEach(callback => callback(data));
  }

  handleReconnect() {
    if (this.reconnectAttempts < this.maxReconnectAttempts) {
      this.reconnectAttempts++;
      console.log(`Reconnecting in ${this.reconnectAttempts} seconds...`);
      this.disconnect()
      setTimeout(() => this.connect(this.path), this.reconnectAttempts * 1000);
    } else {
      console.error('Max reconnect attempts reached. Giving up.');
    }
  }

  disconnect() {
    if (this.socket) {
      this.socket.close();
      this.socket = null;
    }
  }

  checkToken() {
    const newToken = cookies.get('access_token');
    if (newToken !== this.token) {
      console.log('Token changed. Reconnecting...');
      this.disconnect();
      this.token = newToken;
      if (this.token) {
        this.connect(this.path);
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.checkTokenInterval);
    this.disconnect();
  }
}

export default WebSocketClient;
