import React, { createContext, useState, useContext, useEffect } from 'react';
import { API } from '../services/api-service'; 
import { useAccount } from './account';

const PoolContext = createContext();

export const PoolProvider = ({ children }) => {
  const [error, setError] = useState('');
  const [poolData, setPoolData] = useState({});
  const { tokens } = useAccount();
  const accessToken = tokens?.access_token ?? null;

  useEffect(() =>{
    getPoolList()
  },[accessToken])

  const getPoolList = async () => {
    try {
      const response = await API.getPoolList();
      setPoolData(response);
      setError('');
    } catch (error) {
      setError(error.response?.data || { general: 'An error occurred while trying to fetching pools' });
    }
  };

  const attendToPool = async (poolId, ticketQuantity, approximatePrice ) => {
      try {
        setError('');
        const body = { pool:poolId, quantity:ticketQuantity, approximatePrice };
        const response = await API.attendUserToPool(body);
        return response
        
      } catch (error) {
        setError(error.response?.data || { general: 'An error occurred while trying to register for the pool.' });
      }
  };

  return (
    <PoolContext.Provider value={{ actions: { getPoolList, attendToPool }, error, poolData }}>
      {children}
    </PoolContext.Provider>
  );
};

export const usePool = () => useContext(PoolContext);
