import React, { createContext, useState, useContext, useEffect } from 'react';
import { useCookies } from 'react-cookie';

const ThemeContext = createContext();

export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useState(false);
  const [cookies, setCookie] = useCookies(['dark_mode']);

  useEffect(() => {
    const savedTheme = cookies.dark_mode === undefined ? 'false' : cookies.dark_mode;
    setDarkMode(JSON.parse(savedTheme));
  }, [cookies.dark_mode]);

  const updateTheme = (current) => {
    setCookie('dark_mode', current, { path: '/' });
    setDarkMode(current === 'true');
  };

  const toggleTheme = () => {
    const newTheme = darkMode ? 'false' : 'true';
    updateTheme(newTheme);
  };

  return (
    <ThemeContext.Provider value={{ darkMode, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
