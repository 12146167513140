import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAccount } from '../hooks/account';

const PublicRoute = ({ element: Element, restricted = false, ...rest }) => {
  const { tokens } = useAccount();
  const isObjectEmpty = (obj) => 'access_token' in obj || 'refresh_token' in obj;
  const hasKey = isObjectEmpty(tokens)
  return hasKey && restricted ? <Navigate to="/" /> : <Element {...rest} />;
};

export default PublicRoute;