// protected-routes.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAccount } from '../hooks/account';

const ProtectedRoute = ({ element: Element, ...rest }) => {
  const { tokens } = useAccount();
  const isObjectEmpty = (obj) => 'access_token' in obj || 'refresh_token' in obj;
  const hasKey = isObjectEmpty(tokens)
  return hasKey ? <Element {...rest} /> : <Navigate to="/login" />;
};

export default ProtectedRoute;