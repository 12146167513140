import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle,faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const NotFoundPage = () => {
  return (
    <Container className="d-flex justify-content-center align-items-center min-vh-100">
      <Row className="text-center">
        <Col>
          <div className="mb-4">
            <FontAwesomeIcon icon={faExclamationTriangle} size="5x" className="text-warning" />
          </div>
          <h1 className="display-1 fw-bold">404</h1>
          <h2 className="display-4">Oops! Page Not Found</h2>
          <p className="lead">
            The page you are looking for does not exist or has been moved.
          </p>
          <Button href="/" variant="primary" size="lg">
            Go Back Home
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

const InternalServerErrorPage = () => {
  return (
    <Container className="d-flex justify-content-center align-items-center min-vh-100">
      <Row className="text-center">
        <Col>
          <div className="mb-4">
            <FontAwesomeIcon icon={faExclamationCircle} size="5x" className="text-danger" />
          </div>
          <h1 className="display-1 fw-bold">500</h1>
          <h2 className="display-4">Internal Server Error</h2>
          <p className="lead">
            Something went wrong on our end. Please try again later or contact support if the issue persists.
          </p>
          <Button href="/" variant="danger" size="lg">
            Go Back Home
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export { NotFoundPage, InternalServerErrorPage };