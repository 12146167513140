import React from 'react';
import { Row, Col, Card, Accordion } from 'react-bootstrap';
import { useAccount } from '../../hooks/account';
import { usePool } from '../../hooks/pool';
import PoolCard from './pool-data-card';

const PoolData = () => {
  const { profile } = useAccount();
  const { poolData = [] } = usePool();

  return (
    <Accordion className='mt-5' defaultActiveKey="1">
      {Array.isArray(poolData) ? (
        poolData.map(catalogData => (
          <Card key={catalogData.catalog.id} className='mt-3'>
            <Accordion.Item eventKey={catalogData.catalog.id.toString()}>
              <Accordion.Header>{catalogData.catalog.name}</Accordion.Header>
              <Accordion.Body>
                <p>{catalogData.catalog.description ? catalogData.catalog.description : null}</p>
                {catalogData.categories.map(categoryData => (
                  <div className="card mb-4" key={categoryData.category.id}>
                    <h5 className="card-header text-center">{categoryData.category.name}</h5>
                    <div className="card-body mr-1">
                      <p>{categoryData.category.description ? categoryData.category.description : null}</p>
                      <Row xs={1} md={2} lg={3} xl={4} className="g-1">
                        {categoryData.pools.map(pool => (
                          <Col key={pool.id}>
                            <PoolCard
                              pool={pool}
                              profile={profile}
                            />
                          </Col>
                        ))}
                      </Row>
                    </div>
                  </div>
                ))}
              </Accordion.Body>
            </Accordion.Item>
          </Card>
        ))
      ) : null}
    </Accordion>
  );

};

export default PoolData;
