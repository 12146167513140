import { wss } from './connect-service'

export class WS {
  static connect(path) {
    wss.connect(path);
  }

  static disconnect() {
    wss.disconnect();
  }

  static sendMessage(message) {
    wss.sendMessage(message);
  }

  static addListener(callback) {
    wss.addListener(callback);
  }
}