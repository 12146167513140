import React, { createContext, useContext, useEffect } from 'react';
// import { API } from '../services/api-service'; 
import { useAccount } from './account';

const WalletContext = createContext();

export const WalletProvider = ({ children }) => {
  const  error = ''
  const { tokens } = useAccount();
  const accessToken = tokens?.access_token ?? null;

  useEffect(() =>{
  },[accessToken])

  return (
    <WalletContext.Provider value={{ actions: {}, error }}>
      {children}
    </WalletContext.Provider>
  );
};

export const useWallet = () => useContext(WalletContext);
