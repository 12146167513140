import React, { useState, useEffect } from 'react';
import { Nav, Navbar, Container, Collapse, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faUser, faSignInAlt, faUserPlus,faUserPen, faWallet, faChevronDown, faChevronUp, faExchange } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { useTheme } from '../hooks/theme-context';
import { useAccount } from '../hooks/account';
import ProfilePicture from './profile-picture'
const SidebarComponent = () => {
  const { darkMode } = useTheme();
  const [activeSection, setActiveSection] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const { actions: userActions, tokens, appMenu = [] } = useAccount();
  const { fetchMenu } = userActions
  const accessToken = tokens?.access_token ?? null;
  
  const iconMap = {
    "faList": faList,
    "faUser":faUser,
    "faWallet":faWallet,
    "faSignInAlt":faSignInAlt,
    "faUserPlus":faUserPlus,
    "faExchange":faExchange,
    "faUserPen":faUserPen
  };
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  useEffect(() =>{
    fetchMenu()
  },[accessToken])

  const handleToggle = (section) => {
    setActiveSection((prev) => (prev === section ? '' : section));
  };

  if (isMobile) {
    return null; 
  }

  return (
    <Navbar
      bg={darkMode ? 'dark' : 'light'}
      variant={darkMode ? 'dark' : 'light'}
      className={`col-md-3 col-lg-2 d-md-block bg-${darkMode ? 'dark' : 'light'} text-${darkMode ? 'light' : 'dark'} sidebar mt-2 side-bar-full-height rounded`}
      expand="md"
      sticky="top"
    >
      <Container fluid>
        <Nav className="flex-column w-100">
          <Nav.Item className="w-100 mt-4 text-center">
            <ProfilePicture />
          </Nav.Item>
          <Nav.Item className="w-100 mt-4">
            <div className="d-flex justify-content-around mt-4">
              <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className={`text-decoration-none ${darkMode ? 'text-light' : 'text-dark'}`}>
                <FontAwesomeIcon icon={faFacebook} size="2xl" />
              </a>
              <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className={`text-decoration-none ${darkMode ? 'text-light' : 'text-dark'}`}>
                <FontAwesomeIcon icon={faTwitter} size="2xl" />
              </a>
              <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className={`text-decoration-none ${darkMode ? 'text-light' : 'text-dark'}`}>
                <FontAwesomeIcon icon={faInstagram} size="2xl" />
              </a>
              <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className={`text-decoration-none ${darkMode ? 'text-light' : 'text-dark'}`}>
                <FontAwesomeIcon icon={faLinkedin} size="2xl" />
              </a>
            </div>
          </Nav.Item>
          { appMenu && appMenu.map(app => (
          <Nav.Item className="w-100 mt-4" key={app.id}>
            <Button
              onClick={() => handleToggle(app.id)}
              aria-controls={`${app.id}-collapse`}
              aria-expanded={activeSection === app.id}
              variant=""
              className={`d-flex justify-content-between align-items-center px-3 mb-2 border rounded w-100 ${darkMode ? 'text-light' : 'text-muted'} ${darkMode ? 'border-light' : 'border-dark'}`}
            >
              <span className="fw-bold">{app.app_name}</span>
              <FontAwesomeIcon icon={activeSection === app.id ? faChevronUp : faChevronDown} size="lg" />
            </Button>
            <Collapse in={activeSection === app.id}>
              <div id={`${app.id}-collapse`} className="w-100">
                {app.models.map(model => (
                  <LinkContainer to={model.link} key={model.id}>
                    <Nav.Link variant='' className='w-100'>
                      <FontAwesomeIcon icon={iconMap[model.icon]} size="lg" className="me-2" />
                      {model.model_name}
                    </Nav.Link>
                  </LinkContainer>
                ))}
              </div>
            </Collapse>
          </Nav.Item>
        ))}
        </Nav>
      </Container>
    </Navbar>
  );
};

export default SidebarComponent;
