
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import { useEffect } from 'react';
import PoolData from './pool/pool-data'
function App() {

  useEffect(() => {
  }, []);

  return (
    <div className=" mx-auto mt-5 mb-5">
      <h2 className="text-center mb-4">All Pools</h2>
      <PoolData />
    </div>
  );
}

export default App;