import axios from 'axios';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

class RestClient {
  constructor(baseURL) {
    this.baseURL = baseURL;
    this.tokenPrefix = 'JwtToken';
    this.refreshTokenURL = 'user/auth/refresh/';
    this.verifyRefreshTokenURL = 'user/auth/verify_refresh/';
    
    this.api = axios.create({
      baseURL: this.baseURL,
      headers: {
        'Content-Type': 'application/json',
      }
    });

    this.api.interceptors.request.use(
      (config) => {
        const token = cookies.get('access_token');
        if (token) {
          config.headers['Authorization'] = `${this.tokenPrefix} ${token}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    this.api.interceptors.response.use(
      response => response,
      async (error) => {
        const originalRequest = error.config;

        if (error.response && error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          const refreshToken = cookies.get('refresh_token');
          if (refreshToken) {
            try {
              const { data: verifyData } = await axios.post(`${this.baseURL}${this.verifyRefreshTokenURL}`, { token: refreshToken });

              if (verifyData.status) {
                const { data: refreshData } = await axios.post(`${this.baseURL}${this.refreshTokenURL}`, { refresh: refreshToken });
                const newAccessToken = refreshData.access;
                const newRefreshToken = refreshData.refresh;

                cookies.set('access_token', newAccessToken, { path: '/', secure: true, sameSite: 'Strict' });
                cookies.set('refresh_token', newRefreshToken, { path: '/', secure: true, sameSite: 'Strict' });

                originalRequest.headers['Authorization'] = `${this.tokenPrefix} ${newAccessToken}`;
                return this.api(originalRequest);
              } else {
                this.clearCookies();
                console.error('Refresh token verification failed');
                return Promise.reject(error);
              }
            } catch (refreshError) {
              this.clearCookies();
              console.error('Refresh token request failed:', refreshError);
              return Promise.reject(refreshError);
            }
          } else {
            this.clearCookies();
            console.error('No refresh token available');
            return Promise.reject(error);
          }
        }
        console.error('Request failed with status code:', error.response ? error.response.status : 'Unknown');
        return Promise.reject(error);
      }
    );
  }

  clearCookies() {
    cookies.remove('profile');
    cookies.remove('access_token');
    cookies.remove('refresh_token');
  }

  async handleRequest(method, path, body, headers, config) {
    try {
      const response = await this.api.request({
        method,
        url: path,
        data: body,
        headers,
        ...config
      });
      return response.data;
    } catch (error) {
      console.error('Request failed:', error.response ? error.response.data : error.message);
      throw error;
    }
  }

  get(path, headers = {}, config = {}) {
    return this.handleRequest('get', path, null, headers, config);
  }

  post(path, body = {}, headers = {}, config = {}) {
    return this.handleRequest('post', path, body, headers, config);
  }

  put(path, body = {}, headers = {}, config = {}) {
    return this.handleRequest('put', path, body, headers, config);
  }

  delete(path, body = {}, headers = {}, config = {}) {
    return this.handleRequest('delete', path, body, headers, config);
  }
}

export default RestClient;
