import { api } from './connect-service'

export class API {
  static async makeRequest(method, path, body = {}, customHeaders = {}, config = {}) {
    const headers = {
      'Content-Type': 'application/json',
      ...customHeaders,
    };

    const isFormData = obj => obj instanceof FormData;

    const options = method.toLowerCase() === 'get' || method.toLowerCase() === 'delete'
      ? {}
      : isFormData(body)
        ? body
        : { ...body };

    try {
      return await api[method.toLowerCase()](path, options, headers, config);
    } catch (error) {
      console.error(`Error during ${method} request to ${path}:`, error);
      throw error;
    }
  }

  static userLogin(body) {
    return this.makeRequest('POST', 'user/auth/login/', body);
  }

  static getPoolList() {
    return this.makeRequest('GET', 'pool/list/')
  }

  static attendUserToPool(body) {
    return this.makeRequest('POST', 'pool/attend/', body)
  }

  static userRegister(body) {
    return this.makeRequest('POST', 'user/auth/register/', body);
  }

  static sendPasswordResetCode(body) {
    return this.makeRequest('POST', 'user/auth/password-reset/', body);
  }

  static confirmPasswordReset(body) {
    return this.makeRequest('POST', 'user/auth/password-reset-confirm/', body);
  }

  static sendUserRegisterCode(body) {
    return this.makeRequest('POST', 'user/create_code/', body);
  }

  static verifyUserRegister(body) {
    return this.makeRequest('POST', 'user/verify/', body);
  }

  static getProfile() {
    return this.makeRequest('Get', 'user/profile/');
  }

  static getAppMenu() {
    return this.makeRequest('Get', 'user/menu/');
  }

  static coins() {
    return this.makeRequest('GET', 'user/coins/');
  }

  static wallets() {
    return this.makeRequest('GET', 'user/wallets/');
  }

  static createWallet(body) {
    return this.makeRequest('POST', 'user/wallets/', body);
  }

  static userGoogleLogin() {
    return this.makeRequest('GET', 'user/google/login/');
  }

  static userTelegramLogin() {
    return this.makeRequest('GET', 'user/telegram/login/');
  }
  static userTelegramCallback(body) {
    return this.makeRequest('POST', 'user/telegram/callback/', body);
  }

  static userRefreshToken(body) {
    return this.makeRequest('POST', 'user/auth/refresh/', body);
  }

  static updateUserProfile(formData, userId) {
    return this.makeRequest('PUT', `user/profile/${userId}/`, formData, { 'Content-Type': 'multipart/form-data' });
  }

  static listWalletTransactions({ pageNumber }) {
    return this.makeRequest('GET', `user/wallet_transactions/?page=${pageNumber}`);
  }

}