import React, { useState } from 'react';
import { Modal, Button, Row, Col, ListGroup, Form } from 'react-bootstrap';
import { useAccount } from '../../hooks/account';
import { usePool } from '../../hooks/pool';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import CountdownTimer from '../../components/count-down-timer';
function ModalComponent({ showModal, handleCloseModal, handleConfirmModal, modalData = {} }) {
    const { pool = {}, isExist } = modalData;
    const {
        url: youtubeUrl,
        attended,
        purchased: purchasedTickets,
        price: poolPrice,
        average_ticket_quantity: avgTicketQuantity,
        draw_date: drawDate,
        draw_result: drawTickets
    } = pool;
    const [inputValue, setInputValue] = useState('');
    const [calculatedPrice, setCalculatedPrice] = useState(0);
    const { profile } = useAccount();
    const { error } = usePool();
    const balanceSufficient = profile.user_balance >= poolPrice

    const modalType = () => {
        if (youtubeUrl) return 1;
        if (purchasedTickets && purchasedTickets.length > 0 && isExist) return 2;
        if (purchasedTickets && purchasedTickets.length > 0) return 3;
        if (attended === false) return 4;
        return 4;
    };

    const getModalTitle = () => {
        const symbol = pool.coin_stock?.coin?.symbol;
        const currencySymbol = pool.coin_stock?.currency?.symbol;

        switch (modalType()) {
            case 1:
                return `YouTube Link for ${symbol} / ${currencySymbol} ${poolPrice}`;
            case 2:
                return `Purchased Tickets for ${symbol} / ${currencySymbol} ${poolPrice}`;
            case 3:
                return `Buy More Tickets for ${symbol} / ${currencySymbol} ${poolPrice}`;
            case 4:
                return `Buy New Tickets for ${symbol} / ${currencySymbol} ${poolPrice}`;
            default:
                return `Buy New Tickets for ${symbol} / ${currencySymbol} ${poolPrice}`;
        }
    };

    const currentModalType = modalType();

    const handleChange = (e) => {
        let value = e.target.value;
        let qPrice = value * poolPrice
        const factor = 100;
        if (/^\d*$/.test(value)) {
            if (qPrice >= profile.user_balance) {
                const num = (profile.user_balance / poolPrice) | 0;
                value = Math.ceil(num * factor) / factor;
            }
            qPrice = Math.ceil(value * poolPrice * factor) / factor
            setCalculatedPrice(qPrice)
            value = Math.min(value, avgTicketQuantity);
            setInputValue(value);
        }
    };

    const handleSelectedPoolData = () => {
        handleConfirmModal(pool.id, inputValue, calculatedPrice);
        setInputValue('');
    };

    const checkDraw = (code, ticketlist) => {
        let compareList = ticketlist
        const firstElement = ticketlist[0];

        if (typeof firstElement === 'object') {
            compareList = ticketlist.map(item => item.code);
        }

        if (compareList.includes(code)) {
            return <em className='ms-1 badge text-bg-success'>You Win!</em>
        }
    }

    return (
        <Modal show={showModal} onHide={handleCloseModal} centered size="lg" backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{getModalTitle()}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {currentModalType === 2 && purchasedTickets.length > 0 ? (
                    <ListGroup>
                        {purchasedTickets.map((item, index) => (
                            <ListGroup.Item key={item.code || index}>
                                <Row className="rounded-2xl mt-1 mb-1">
                                    <Col><strong>Quantity : </strong> {item.quantity}</Col>
                                    <Col><strong>Code : </strong> {item.code}{checkDraw(item.code, drawTickets)}</Col>
                                </Row>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                ) : currentModalType === 1 ? (
                    <ListGroup>
                        <ListGroup.Item key={`pool_1_${pool.id}`} className='ms-4 mt-2 me-4'>
                            <Row className="g-0 rounded-2xl mt-1 mb-1">
                                <Col xs={8} sm={6} md={8} className="d-flex align-items-center">
                                    <em>You can watch after </em>
                                </Col>
                                <Col xs={4} sm={6} md={4} className="d-flex align-items-center justify-content-end">
                                    <em><CountdownTimer dueDate={drawDate} isCompleted={false} /></em>
                                </Col>
                            </Row>
                        </ListGroup.Item>
                        {drawTickets.map((item, index) => (
                            <ListGroup.Item key={`draw-ticket-${index}`} className='ms-4 me-4' >
                                <Row className="g-0 rounded-2xl mt-1 mb-1">
                                    <Col xs={8} sm={6} md={8} className="d-flex align-items-center" ><em>Winner Ticket Number</em></Col>
                                    <Col xs={4} sm={6} md={4} className="d-flex align-items-center justify-content-end" ><em>{item}</em>{checkDraw(item, purchasedTickets)}</Col>
                                </Row>
                            </ListGroup.Item>
                        ))}
                        <ListGroup.Item key={`pool_2_${pool.id}`} className='ms-4 mb-2 me-4'>
                            <Row className="g-0 rounded-2xl mt-1 mb-1">
                                <Col xs={8} sm={6} md={8} className="d-flex align-items-center">
                                    <em>Here is the YouTube link</em>
                                </Col>
                                <Col xs={4} sm={6} md={4} className="d-flex align-items-center justify-content-end">
                                    <a className='badge text-bg-danger badge-lg d-flex align-items-center' href={youtubeUrl} target="_blank" rel="noopener noreferrer">
                                        <FontAwesomeIcon className="text-light me-2" size="lg" icon={faYoutube} />
                                        YouTube
                                    </a>
                                </Col>
                            </Row>
                        </ListGroup.Item>
                    </ListGroup>
                ) : currentModalType === 4 || currentModalType === 3 ? (
                    <Form.Group className='row'>
                        <ListGroup>
                            <ListGroup.Item key={`pool_1_${pool.id}`} className='ms-4 mb-2 me-4'>
                                <Row className="g-0 rounded-2xl mt-1 mb-1">
                                    <Col xs={6} md={8} className="d-flex align-items-center">
                                        <em>Your Total Available Balance</em>
                                    </Col>
                                    <Col xs={6} md={4} className="d-flex align-items-center justify-content-end">
                                        <em className='badge text-bg-primary'>{`${profile.user_balance} ${profile.user_currency}`}</em>
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                        </ListGroup>
                        {balanceSufficient ? (
                            <>
                                <ListGroup>
                                    <ListGroup.Item key={`pool_2_${pool.id}`} className='ms-4 mb-2 me-4'>
                                        <Row className="g-0 rounded-2xl mt-1 mb-1">
                                            <Col xs={6} md={8} className="d-flex align-items-center">
                                                <em>The Total Amount To Be Paid From Your Account For This Transaction</em>
                                            </Col>
                                            <Col xs={6} md={4} className="d-flex align-items-center justify-content-end">
                                                <em className='badge text-bg-primary'>{`${calculatedPrice} ${profile.user_currency}`}</em>
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                </ListGroup>
                                <ListGroup>
                                    <ListGroup.Item key={`pool_1_${pool.id}`} className='ms-4 mb-2 me-4'>
                                        <Row className="g-0 rounded-2xl mt-1 mb-1"><Form.Label className='text-center'>Please Enter The Quantity For The Ticket You Want To Purchase</Form.Label></Row>
                                        <Row className="g-0 rounded-2xl mt-1 mb-1">
                                            <Form.Control
                                                type="text"
                                                value={inputValue}
                                                onChange={handleChange}
                                            />
                                            {error.quantity && <Form.Text className="text-danger">{error.quantity}</Form.Text>}
                                        </Row>
                                    </ListGroup.Item>
                                </ListGroup>
                            </>
                        ) : (
                            <>
                                <ListGroup>
                                    <ListGroup.Item key={`pool_1_${pool.id}`} className='ms-4 mb-2 me-4'>
                                        <Row className="g-0 rounded-2xl mt-1 mb-1">
                                            <Col xs={12} md={12} className="d-flex align-items-center">
                                                <em>You Do Not Have Sufficient Balance To Complete This Transaction</em><br />
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                </ListGroup>
                                <ListGroup>
                                    <ListGroup.Item key={`pool_1_${pool.id}`} className='ms-4 mb-2 me-4'>
                                        <Row className="g-0 rounded-2xl mt-1 mb-1">
                                            <Col xs={12} md={12} className="d-flex align-items-center">
                                                <em>Please Try Again After Adding Funds To Your Balance</em>
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                </ListGroup>
                            </>
                        )}
                    </Form.Group>
                ) : null}
            </Modal.Body>
            <Modal.Footer>
                {(currentModalType === 4 || currentModalType === 3) ? (
                    balanceSufficient ? (
                        <>
                            <Button variant="secondary" onClick={handleCloseModal}>Cancel</Button>
                            <Button variant="primary" onClick={() => handleSelectedPoolData()}>Confirm</Button>
                        </>
                    ) : <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
                ) : (
                    <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
                )}
            </Modal.Footer>
        </Modal>
    );
}

export default ModalComponent;
