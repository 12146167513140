import RestClient from './rest-client';
import WebSocketClient from './ws-client';

const DOMAIN = window.location.hostname;
const HOSTNAME = DOMAIN.split('.').slice(-2).join('.'); 
const SERVER = `api.${HOSTNAME}`;
const HTTP_METHOD = 'https';
const WS_METHOD = 'wss';
const HTTP_PORT = '443';
const WS_PORT = '443';

const HTTP_BASE_URL = `${HTTP_METHOD}://${SERVER}:${HTTP_PORT}/`;
const WS_BASE_URL = `${WS_METHOD}://${SERVER}:${WS_PORT}/`;

const api = new RestClient(HTTP_BASE_URL);
const wss = new WebSocketClient(WS_BASE_URL);

export { api, wss };