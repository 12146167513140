import React from 'react';
import { createRoot } from 'react-dom/client';
// import './index.css';
import reportWebVitals from './reportWebVitals';
import { CookiesProvider } from 'react-cookie';
import {ThemeProvider} from './hooks/theme-context';
import MainRouteComponent from './components/route-component';

function AppRouter() {
  return (
    <React.StrictMode>
      <CookiesProvider>
        <ThemeProvider>
          <MainRouteComponent />
        </ThemeProvider>
      </CookiesProvider>
    </React.StrictMode>
  );
}

const root = createRoot(document.getElementById('root'));

root.render(
  <AppRouter />
);

reportWebVitals();
