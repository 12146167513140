import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavbarComponent from '../components/navbar-component';
import SidebarComponent from '../components/sidebar-component';
import { useTheme } from '../hooks/theme-context';
import { UserProvider } from '../hooks/account';
import { PoolProvider } from '../hooks/pool';
import { WalletProvider } from '../hooks/wallet';
import App from '../pages/App';

import ProtectedRoute from './protected-routes';
import PublicRoute from './public-routes';

const UserLogin = React.lazy(() => import('../pages/user/login'));
const UserRegister = React.lazy(() => import('../pages/user/register'));
const UserPasswordReset = React.lazy(() => import('../pages/user/password-reset'));
const UserProfile = React.lazy(() => import('../pages/user/profile'));
const UserWallet = React.lazy(() => import('../pages/wallet/wallet-details'));
const WalletTransaction =  React.lazy(() => import('../pages/wallet/transactions'));

const MainRouteComponent = () => {
  const { darkMode } = useTheme();

  return (
    <div className={darkMode ? 'bg-dark text-light full-height' : 'full-height'}>
      <Router>
        <UserProvider>
          <PoolProvider>
            <NavbarComponent />
            <div className='container-fluid main-body'>
              <div className='row'>
                <SidebarComponent />
                <main className='col-md-9 ms-sm-auto col-lg-10 px-md-4'>
                  <Suspense fallback={<div>Loading...</div>}>
                    <Routes>
                      <Route
                        path="/"
                        element={
                          <PublicRoute
                            element={() => (
                              <div className={`row align-items-center mt-2 card ${darkMode ? '' : 'text-bg-light'}`}>
                                <App />
                              </div>
                            )}
                          />}
                      />
                      <Route
                        path="/login"
                        element={
                          <WalletProvider>
                            <PublicRoute
                              element={() => (
                                <div className={`row align-items-center mt-2 card ${darkMode ? '' : 'text-bg-light'}`}>
                                  <UserLogin />
                                </div>
                              )}
                              restricted={true}
                            />
                          </WalletProvider>
                        }
                      />
                      <Route
                        path="/register"
                        element={
                          <PublicRoute
                            element={() => (
                              <div className={`row align-items-center mt-2 card ${darkMode ? '' : 'text-bg-light'}`}>
                                <UserRegister />
                              </div>
                            )}
                            restricted={true}
                          />
                        }
                      />
                      <Route
                        path="/password_reset"
                        element={
                          <PublicRoute
                            element={() => (
                              <div className={`row align-items-center mt-2 card ${darkMode ? '' : 'text-bg-light'}`}>
                                <UserPasswordReset />
                              </div>
                            )}
                          />
                        }
                      />
                      <Route
                        path="*"
                        element={<PublicRoute element={App} />}
                      />
                      <Route
                        path="/profile"
                        element={<ProtectedRoute element={() => (
                          <div className={`row align-items-center mt-2 card ${darkMode ? '' : 'text-bg-light'}`}>
                            <UserProfile />
                          </div>
                        )} />}
                      />
                      <Route
                        path="/wallet"
                        element={<ProtectedRoute element={() => (
                          <div className={`row align-items-center mt-2 card ${darkMode ? '' : 'text-bg-light'}`}>
                            <UserWallet />
                          </div>
                        )} />}
                      />
                      <Route
                        path="/wallet_transactions"
                        element={<ProtectedRoute element={() => (
                          <div className={`row align-items-center mt-2 card ${darkMode ? '' : 'text-bg-light'}`}>
                            <WalletTransaction />
                          </div>
                        )} />}
                      />
                      {/* Diğer rotalar */}
                    </Routes>
                  </Suspense>
                </main>
              </div>
            </div>
          </PoolProvider>
        </UserProvider>
      </Router>
    </div>
  );
};

export default MainRouteComponent;
