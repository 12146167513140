import React, { createContext, useState, useContext, useEffect } from 'react';
import { API } from '../services/api-service';
import { useCookies } from 'react-cookie';
import { useNavigate, useLocation } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { WS } from '../services/ws-service';
import { InternalServerErrorPage } from '../components/html-errors-form';
const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [error, setError] = useState({});
  const [profile, setProfile] = useState({});
  const [email, setEmail] = useState('');
  const [appMenu, setAppMenu] = useState([]);
  const [coinList, setCoinList] = useState([])
  const [walletList, setWalletList] = useState([])
  const isFirstTab = true

  const [cookies, setCookie, removeCookie] = useCookies(['access_token', 'refresh_token', 'profile', 'tab_number']);
  const accessToken = cookies?.access_token ?? null;
  const baseURL = `https://api.${window.location.hostname.split('.').slice(-2).join('.')}`;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      if (accessToken) {
        await fetchProfile();
      }
    };

    fetchData();
  }, [accessToken]);

  const location = useLocation();

  useEffect(() => {
    const fetchTokenData = async () => {
      const queryParams = new URLSearchParams(location.search);
      const token = queryParams.get('token');
      if (token) {
        try {
          const { access, refresh } = await API.userRefreshToken({ refresh: token });
          setCookie('access_token', access, { path: '/', secure: true, sameSite: 'Strict' });
          setCookie('refresh_token', refresh, { path: '/', secure: true, sameSite: 'Strict' });
        } catch (error) {
          navigate('/login');
          setError(`Error refreshing token : ${error}`)
          console.error('Error refreshing token:', error);
        }
        queryParams.delete('token');
        window.history.replaceState(null, '', `${location.pathname}?${queryParams.toString()}`);
        navigate('/');
      }
    };

    fetchTokenData();
  }, [location.search]);

  useEffect(() => {
    if (profile?.is_email_editable) {
      setError(prevError => ({
        ...prevError,
        email_edit: 'To avoid any issues with real-time operations and to enable password-based login, you need to update your currently used email address.'
      }));
      navigate('/profile');
    }
  });

  useEffect(() => {
    const fetchTokenData = async () => {
      if (location.hash.includes('tgAuthResult')) {
        const hashArray = location.hash.split('=');
        const code = hashArray[1];
        window.history.replaceState(null, '', location.pathname + location.search);
        const response = await API.userTelegramCallback({ 'tgAuthResult': code });
        const { access, refresh } = response;
        setCookie('access_token', access, { path: '/', secure: true, sameSite: 'Strict' });
        setCookie('refresh_token', refresh, { path: '/', secure: true, sameSite: 'Strict' });
        const decodedProfile = jwtDecode(access);
        await fetchProfile(decodedProfile);
        navigate('/');
      }
    };
    fetchTokenData();
  }, [location.hash]);

  const handleGoogleLogin = async () => {
    try {
      const { auth_url } = await API.userGoogleLogin();
      window.location.assign(auth_url);
    } catch (error) {
      console.error('OAuth başlatılamadı:', error);
    }
  };

  const handleTelegramLogin = async () => {
    try {
      const { auth_url } = await API.userTelegramLogin();
      window.location.assign(auth_url);
    } catch (error) {
      console.error('OAuth başlatılamadı:', error);
    }
  };

  useEffect(() => {
    WS.connect('user/ws/get_profile');
    WS.addListener((data) => {
      if (data?.type === 'profile') {
        const updatedData = { ...data.data, profile_picture: data.data.profile_picture ? `${baseURL}${data.data.profile_picture}` : null };
        setProfile(updatedData);
        setCookie('profile', JSON.stringify(updatedData), { path: '/', secure: true, sameSite: 'Strict' });
        fetchUserWallets();
      }
    });

    return () => {
      WS.disconnect();
    };
  }, []);

  const fetchProfile = async (data = null) => {
    setError('');

    try {
      if (accessToken) {
        if (data) {
          const updatedData = { ...data, profile_picture: data.profile_picture ? `${baseURL}${data.profile_picture}` : null };
          setProfile(updatedData);
          setCookie('profile', JSON.stringify(data), { path: '/', secure: true, sameSite: 'Strict' });
        } else {
          const response = await API.getProfile();
          const updatedResponse = { ...response, profile_picture: response.profile_picture ? `${baseURL}${response.profile_picture}` : null };
          setProfile(updatedResponse);
          setCookie('profile', JSON.stringify(response), { path: '/', secure: true, sameSite: 'Strict' });
        }
      }
    } catch (error) {
      setError(error.response?.data || { general: 'Failed to load profile.' });
    }
  };

  const fetchMenu = async () => {
    setError('');
    try {
      const response = await API.getAppMenu();
      setAppMenu(response);
    } catch (error) {
      setError(error.response?.data || { general: 'Failed to load menu.' });
    }
  };

  const login = async (email, password) => {
    setError('');
    try {
      const response = await API.userLogin({ email, password });
      const { access, refresh } = response;
      setCookie('access_token', access, { path: '/', secure: true, sameSite: 'Strict' });
      setCookie('refresh_token', refresh, { path: '/', secure: true, sameSite: 'Strict' });
      const decodedProfile = jwtDecode(access);
      await fetchProfile(decodedProfile);
      navigate('/');
    } catch (error) {
      setError(error.response?.data || { general: 'Login failed. Please check your credentials.' });
    }
  };

  const register = async (email, password, repassword, referalCode) => {
    setError('');
    try {
      const referal_code = referalCode.trim() || null;
      const body = { password, repeat_password: repassword, email, referal_code };
      await API.userRegister(body);
      await login(email, password);
    } catch (error) {
      setError(error.response?.data || { general: 'Registration failed.' });
    }
  };

  const logout = () => {
    if (window.confirm('Are you sure you want to logout?')) {
      removeCookie('profile', { path: '/', secure: true, sameSite: 'Strict' });
      removeCookie('access_token', { path: '/', secure: true, sameSite: 'Strict' });
      removeCookie('refresh_token', { path: '/', secure: true, sameSite: 'Strict' });
      setProfile({});
      navigate('/');
    }
  };

  const sendRegisterCode = async (email, password, repassword, referalCode) => {
    setError('');
    try {
      const body = { email };
      const data = await API.sendUserRegisterCode(body);
      if (data?.verified?.[0] === 'true') {
        await register(email, password, repassword, referalCode);
        return true;
      }
      return data;
    } catch (error) {
      setError(error.response?.data || { general: 'Code send failed' });
    }
  };

  const verifyRegisterCode = async (email, verification_code) => {
    setError('');
    try {
      const body = { email, verification_code };
      return await API.verifyUserRegister(body);
    } catch (error) {
      setError(error.response?.data || { general: 'Verification failed' });
    }
  };

  const sendPasswordResetCode = async (email) => {
    setError('');
    try {
      const body = { email };
      return await API.sendPasswordResetCode(body);
    } catch (error) {
      setError(error.response?.data || { general: 'Verification Code send failed' });
      return error.response.data
    }
  };

  const confirmPasswordReset = async (email, password, repassword, code) => {
    setError('');
    try {
      const body = { password, repeat_password: repassword, email, code };
      const resp = await API.confirmPasswordReset(body);
      return resp
    } catch (error) {
      setError(error.response?.data || { general: 'Password reset failed' });
      return false
    }
  };

  const walletCoins = async () => {
    setError('');
    try {
      const allCoins = await API.coins();
      setCoinList(allCoins)
    } catch (error) {
      setError(error.response?.data || { general: 'An error occurred while trying to fetching coin list' });
    }
  }

  const fetchUserWallets = async () => {
    setError('');
    try {
      walletCoins()
      const wallets = await API.wallets();
      setWalletList(wallets)
    } catch (error) {
      setError(error.response?.data || { general: 'An error occurred while trying to fetching wallet list' });
    }
  }

  const listWalletTransction = async (pageNumber) => {
    setError('');
    try {
      const transactions = await API.listWalletTransactions({ pageNumber });
      return transactions
    } catch (error) {
      setError(error.response?.data || { general: 'An error occurred while trying to listing transactions' });
      return false
    }
  }

  const createUserWallet = async (coin, wallet_address) => {
    setError('');
    try {
      const body = { coin, wallet_address };
      const new_wallet = await API.createWallet(body);
      if (new_wallet) {
        fetchUserWallets()
      }
      return new_wallet
    } catch (error) {
      setError(error.response?.data || { general: 'An error occurred while trying to create wallet' });
      return false
    }

  }

  const updateUserProfile = async (formData, userId) => {
    setError({});
    try {
      const newProfile = await API.updateUserProfile(formData, userId);
      const updatedProfile = { ...newProfile, profile_picture: newProfile.profile_picture ? `${baseURL}${newProfile.profile_picture}` : null };
      setProfile(updatedProfile);
      setError({});
      return updatedProfile
    } catch (error) {
      setError(error.response?.data || { general: 'An error occurred while trying to update user profile' });
      return false
    }

  }

  const accountData = {
    error,
    profile,
    coinList,
    walletList,
    appMenu,
    email,
    tokens: cookies
  };

  const functions = {
    setEmail,
    setError,
    register,
    login,
    handleGoogleLogin,
    handleTelegramLogin,
    sendPasswordResetCode,
    confirmPasswordReset,
    fetchUserWallets,
    listWalletTransction,
    createUserWallet,
    updateUserProfile,
    fetchProfile,
    logout,
    sendRegisterCode,
    verifyRegisterCode,
    fetchMenu,
  };

  return (
    <>
      {isFirstTab ? <UserContext.Provider value={{ actions: functions, ...accountData }}> {children} </UserContext.Provider> : <InternalServerErrorPage />}
    </>
  );
};

export const useAccount = () => useContext(UserContext);
