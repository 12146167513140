import React, { useState } from 'react';
import { Button, Card, ListGroup, ProgressBar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { useNavigate } from 'react-router-dom';
import CountdownTimer from '../../components/count-down-timer';
import ModalComponent from './pool-attend'
import { useAccount } from '../../hooks/account';
import { usePool } from '../../hooks/pool';

const ProgressBarComponent = ({ occupancyRate }) => {
    let now = occupancyRate;
    let variant = 'primary';

    if (occupancyRate < 10 && occupancyRate > 0.9) {
        now = 10;
    }

    if (25 <= now && now < 50) {
        variant = 'success';
    } else if (50 <= now && now < 75) {
        variant = 'warning';
    } else if (now >= 75) {
        variant = 'danger';
    }

    return (
        <ProgressBar
            now={now}
            label={`${occupancyRate}%`}
            striped
            variant={variant}
            animated
        />
    );
};

const PoolCard = ({ pool, profile }) => {
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);
    const [modalData, setModalData ] = useState({})
    const { actions: userActions } = useAccount();
    const { actions: poolActions } = usePool();
    
    const {fetchProfile:setProfile } = userActions
    const { getPoolList:setPoolData, attendToPool } = poolActions

    const poolDataActions = async({ poolDetails, isExist = false }) => {
        await setProfile()
        await setPoolData()
        setModalData({ pool:poolDetails, isExist:isExist})
        handleShowModal();
    };

    const modalConfirm = async (pollID, quantity, calculatedPrice)=>{
        const attended_pool = await attendToPool(pollID, quantity, calculatedPrice)
        if(attended_pool){
            await setProfile()
            await setPoolData()
            handleCloseModal();
        }
    }

    return (
        <Card className="m-4 border border-secondary">
            <Card.Header className='text-center'>
                <b>{pool.coin_stock.coin.symbol}</b> : <b>{pool.coin_stock.price} <b>{pool.coin_stock.currency.symbol}</b></b>
            </Card.Header>
            <Card.Body>
                <ListGroup variant="flush">
                    <ListGroup.Item className='text-center'>
                        <b>Per Ticket Price : {pool.price}</b> <b>{pool.coin_stock.currency.symbol}</b>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <ProgressBarComponent
                            poolId = {pool.id}
                            occupancyRate={pool.occupancy_rate}
                        />
                    </ListGroup.Item>
                    <ListGroup.Item className='text-center'>
                    <CountdownTimer dueDate={pool.due_date} isCompleted={pool.occupancy_rate >= 100} />
                    </ListGroup.Item>
                    {pool.url ? (
                    <ListGroup.Item>
                        <Button variant="danger" className="w-100 btn-sm" onClick={() => poolDataActions({ poolDetails:pool, isExist:pool.attended })}>
                            <FontAwesomeIcon className={`ms-3 text-light`} size="xl" icon={faYoutube} /> Watch On Youtube
                        </Button>
                    </ListGroup.Item>) :
                    (profile.email ? (
                        !pool.attended ? (
                            <ListGroup.Item>
                                <Button variant="success" className="w-100 btn-sm" onClick={() => poolDataActions({ poolDetails:pool, isExist:pool.attended })}>Buy Tickets</Button>
                            </ListGroup.Item>
                        ) : (
                            <ListGroup.Item>
                                <div className="d-flex justify-content-center gap-2">
                                    <Button variant="secondary" className="w-100 btn-sm text-truncate d-inline-block" onClick={() => poolDataActions({ poolDetails:pool, isExist:false })}>Buy More Tickets</Button>
                                    {pool.purchased && pool.purchased.length > 0 ? <Button variant="secondary" className="w-100 btn-sm text-truncate d-inline-block" onClick={() => poolDataActions({ poolDetails:pool, isExist:pool.attended })}>Purchased Tickets</Button> : null}
                                </div>
                            </ListGroup.Item>
                        )
                    ) : (
                        <ListGroup.Item className='text-center'>
                            <div className="d-flex justify-content-center gap-2">
                                <Button variant="success" className="btn-sm" onClick={() => navigate('/login')}>Login</Button>
                                <Button variant="success" className="btn-sm" onClick={() => navigate('/register')}>Register</Button>
                            </div>
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            </Card.Body>
            <ModalComponent 
                showModal={showModal}
                handleCloseModal={handleCloseModal}
                modalData={modalData}
                handleConfirmModal ={modalConfirm} />
        </Card>
    );
};


export default PoolCard;